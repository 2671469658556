
    import axios from 'axios';
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class EconomyStockMarket extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        private readonly errors: any[]     = [];

        private readonly stockPriceGraph = {
            // tslint:disable
            traces: [
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Tesla',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "#CCCCCC", width: 1}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Amazon',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "#FF9900", width: 1}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Apple',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "#666666", width: 1}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Google',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "#4285F4", width: 1}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Microsoft',
                    x:      ['2017-01-17', '2017-01-18', '2017-01-19'],
                    y:      [100, 120, 130],
                    line:   {color: "#7FBA00", width: 1}
                }
            ],
            // tslint:enable
        };

        private readonly companyValueGraph2019 = {
            // tslint:disable
            traces: [
                {
                    type: "bar",
                    x: ["Apple", "Microsoft", "Amazon", "Facebook", "Google", "Samsung", "Netflix", "Tesla", "Sony", "Nintendo"],
                    y: [1203294557700, 1137727550000, 911437920000, 589243620000, 490554484750, 191249000000, 161980787260, 109318369999.999985, 95242000000, 42780000000],
                    marker: {
                        color: ['#666666', '#7FBA00','#FF9900', '#4267B2', '#4285F4', '#034EA2', '#E50914', '#CCCCCC', '#697D99', '#FE0016'],
                    },
                }
            ],
            // tslint:enable
        };

        private readonly companySharesGraph2019 = {
            // tslint:disable
            traces: [
                {
                    type: "bar",
                    x: ["Apple", "Microsoft", "Amazon", "Facebook", "Google", "Netflix", "Tesla"],
                    y: [4617834000, 7673000000, 494000000, 2854000000, 346667000, 437799000, 177000000],
                    marker: {
                        color: ['#666666', '#7FBA00','#FF9900', '#4267B2', '#4285F4', '#E50914', '#CCCCCC'],
                    },
                }
            ],
            // tslint:enable
        };

        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                amazonImagePathAndName:
                    require("@/assets/images/amazon.png"),

                appleImagePathAndName:
                    require("@/assets/images/apple.png"),

                stockMarketImagePathAndName:
                    require("@/assets/images/stock_market.png"),

                stockMarketIconPathAndName:
                    require("@/assets/images/icon_stock_market.png"),

                teslaImagePathAndName:
                    require("@/assets/images/tesla.png"),
            };
        }

        private created() {
            this.requestAlphaVantangeJson();
        }

        private requestAlphaVantangeJson(): void {
            const alphavantageURL       = "https://www.alphavantage.co/query?";
            const weekly                = "function=TIME_SERIES_WEEKLY";
            const teslaSymbol           = "&symbol=TSLA";
            const market                = "&market=GBP";
            const apiKey                = "&apikey=Z9EFUZXYZ82F9YES";
            const teslaUrl              = alphavantageURL + weekly + teslaSymbol + market + apiKey;
            const teslaIndex            = 0;
            this.downloadStatistics(teslaUrl, this.stockPriceGraph, teslaIndex);

            const amazonSymbol          = "&symbol=AMZN";
            const amazonUrl             = alphavantageURL + weekly + amazonSymbol + market + apiKey;
            const amazonIndex           = 1;
            this.downloadStatistics(amazonUrl, this.stockPriceGraph, amazonIndex);

            const appleSymbol           = "&symbol=AAPL";
            const appleUrl              = alphavantageURL + weekly + appleSymbol + market + apiKey;
            const appleIndex            = 2;
            this.downloadStatistics(appleUrl, this.stockPriceGraph, appleIndex);

            const googleSymbol          = "&symbol=GOOGL";
            const googleUrl             = alphavantageURL + weekly + googleSymbol + market + apiKey;
            const googleIndex           = 3;
            this.downloadStatistics(googleUrl, this.stockPriceGraph, googleIndex);

            const microsoftSymbol       = "&symbol=MSFT";
            const microsoftUrl          = alphavantageURL + weekly + microsoftSymbol + market + apiKey;
            const microsoftIndex        = 4;
            this.downloadStatistics(microsoftUrl, this.stockPriceGraph, microsoftIndex);
        }

        private downloadStatistics(url: string, graph: any, traceIndex: number): void {
            axios.get(url).then((response: any) => {
                this.parseToGraph(graph, response.data["Weekly Time Series"], traceIndex);
            }).catch((e) => { this.errors.push(e); });
        }

        private parseToGraph(graph: any, data: any, traceIndex: number): void {
            graph.traces[traceIndex].x      = [];
            graph.traces[traceIndex].y      = [];

            Object.keys(data).forEach((key: any) => {
                const date          = new Date(key);
                const threshold     = 2016;
                if (date.getFullYear() > threshold) {
                    const low   = data[key]["3. low"];
                    graph.traces[traceIndex].x.push(key);
                    graph.traces[traceIndex].y.push(low);
                }
            });
        }

        private get createDefaultLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("", "", 64, 16);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 0.0, y: 1.0, xanchor: 'left', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private get createCompanyValueLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "", 48, 48);
            // tslint:enable
        }

        private get createSharesLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "", 48, 48);
            // tslint:enable
        }
    }
