import { render, staticRenderFns } from "./economy_stock_market.vue?vue&type=template&id=0ae2c4a3&scoped=true&lang=pug"
import script from "./economy_stock_market.vue?vue&type=script&lang=ts"
export * from "./economy_stock_market.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae2c4a3",
  null
  
)

export default component.exports